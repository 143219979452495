$desktop-width: 1000px;

.sectionContainer {
  width: 100%;
  padding-top: 100px;

  @media screen and (min-width: $desktop-width) {
    padding-top: 250px;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: matrix(1, -0.05, 0, 1, 0, 0);
}

.title {
  font-family: chaney, sans-serif;
  font-weight: 400;
  font-size: 25px;
  background-image: linear-gradient(120deg, #ffffff 0%, #ffffff 100%);
  color: transparent;
  background-clip: text;
  line-height: 1;
  width: 100%;
  margin: 25px auto 0;
  letter-spacing: 6px;
  text-align: center;
  position: relative;
  z-index: 1;

  @media screen and (min-width: $desktop-width) {
    background-image: linear-gradient(120deg, #0f23ea 0%, #0d034b 100%);
    font-size: 50px;
  }
}

.imageContainer {
  margin: 50px auto 0;
  width: 95%;
  max-width: 307px;

  @media screen and (min-width: $desktop-width) {
    margin: 30px auto 0;
    max-width: 987px;
  }

  img {
    width: 100%;
    aspect-ratio: 307 / 521;

    @media screen and (min-width: $desktop-width) {
      aspect-ratio: 3 / 1;
    }
  }
}
