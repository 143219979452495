$desktop-width: 1000px;

.socialContainer {
  display: none;
  pointer-events: none;

  @media screen and (min-width: 1400px) {
    display: inline-block;
    position: fixed;
    top: 440px;
    left: 0;
    right: 0;
    width: 95%;
    max-width: 1900px;
    margin: 0 auto;
    z-index: 1;
  }

  @media screen and (min-width: 1960px) {
    max-width: 1800px;
  }
}

.socialWrapper {
  display: inline-block;
  pointer-events: initial;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 30px;
    height: 220px;
  }

  li {
    position: relative;
    flex: 1;
  }
}

.socialIcon {
  width: 27px;
  height: auto;
  aspect-ratio: 1 / 1;
  transition: transform 50ms;

  &:hover {
    transform: scale(1.15);
  }
}
