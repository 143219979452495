$desktop-width: 1000px;

.sectionContainer {
  width: 100%;
  padding-top: 100px;
  position: relative;

  @media screen and (min-width: $desktop-width) {
    padding-top: 200px;
  }
}

.sectionWrapper {
  width: 100%;
  max-width: 880px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 80px;

  @media screen and (min-width: $desktop-width) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto 0;
    padding: 0 10px 80px;
    gap: 20px;
  }
}

.socialContainer {
  width: 100%;
  max-width: 290px;
  margin-top: 20px;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;

  ul {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: space-between;
  }

  li {
    position: relative;
  }

  @media screen and (min-width: $desktop-width) {
    display: none;
  }
}

.socialIcon {
  width: 30px;
  transition: transform 50ms;

  &:hover {
    transform: scale(1.15);
  }
}

.logoAndLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $desktop-width) {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
}

.logo {
  width: 170px;
  height: auto;
  aspect-ratio: 53 / 11;

  @media screen and (min-width: $desktop-width) {
    width: 221px;
  }
}

.linksContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 65px;
  width: 100%;
  max-width: 310px;
  gap: 0 10px;

  @media screen and (min-width: $desktop-width) {
    flex-wrap: nowrap;
    margin-top: initial;
    max-width: initial;
    gap: 40px;
  }
}

.link {
  width: 48%;
  margin-bottom: 20px;
  list-style-type: none;

  @media screen and (min-width: $desktop-width) {
    width: initial;
    margin-bottom: initial;
  }

  a {
    text-decoration: none;
    color: #ffffff;
    font-size: 17px;
    font-family: space-grotesk, sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    padding-left: 25px;

    @media screen and (min-width: $desktop-width) {
      padding-left: initial;
    }
  }
}

.copyright {
  width: 100%;
  text-align: center;
  margin-top: 80px;
  color: #ffffff;

  @media screen and (min-width: $desktop-width) {
    display: none;
  }

  span {
    display: block;
  }

  p {
    font-size: 15px;
    line-height: 1.5;
  }
}
