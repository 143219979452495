$desktop-width: 1000px;

.sectionContainer {
  height: 850px;
  background-image: url("../../../../assets/images/home-cover-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-top: 80px;
  z-index: 0;

  @media screen and (min-width: $desktop-width) {
    height: 1100px;
    padding-top: initial;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::before {
    position: absolute;
    content: "";
    background-image: url("../../../../assets/images/home-cover-lines.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.imageWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  margin: 0 auto;

  &::after {
    display: none;

    @media screen and (min-width: $desktop-width) {
      display: block;
      position: absolute;
      content: url("../../../../assets/images/home-cover-spaceship.svg");
      right: 60px;
      bottom: 150px;
      left: initial;
      top: initial;
      width: initial;
      height: initial;
    }
  }
}

.hawkImg {
  position: absolute;
  width: 100%;
  height: 540px;
  bottom: 0;
  object-fit: cover;
  aspect-ratio: 2473 / 1010;
  object-position: 50% 50%;

  @media screen and (min-width: $desktop-width) {
    height: 100%;
    object-fit: none;
    object-position: 30% 100%;
  }

  @media screen and (min-width: 2000px) {
    object-position: 20% 100%;
  }

  @media screen and (min-width: 2200px) {
    object-position: -20% 100%;
  }

  @media screen and (min-width: 2400px) {
    object-position: 70% 100%;
  }
}

.sectionWrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  color: #ffffff;
  position: relative;
  z-index: 2;

  @media screen and (min-width: $desktop-width) {
    max-width: 1780px;
    padding-left: 30px;
    margin: -20px auto;
  }
}

.textContainer {
  width: 100%;
  padding: 0 10px;

  @media screen and (min-width: $desktop-width) {
    width: initial;
    margin-left: clamp(50px, 5.5vw, 100px);
  }

  @media screen and (min-width: 1750px) {
    margin-left: 200px;
  }

  &::after {
    display: none;

    @media screen and (min-width: $desktop-width) {
      display: block;
      position: absolute;
      content: url("../../../../assets/images/home-cover-graph.svg");
      bottom: -190px;
      left: 100px;
    }
  }
}

.title {
  font-family: chaney, sans-serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 1;
  transform: var(--matrix);
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  letter-spacing: 6px;

  @media screen and (min-width: $desktop-width) {
    max-width: 475px;
    font-size: 75px;
    line-height: 1.1;
  }
}

.subtitle {
  font-family: space-grotesk, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  transform: var(--matrix);
  width: 100%;
  max-width: 312px;
  margin: 25px auto 0;
  padding-left: 7px;
  letter-spacing: 1px;

  @media screen and (min-width: $desktop-width) {
    max-width: 470px;
    font-size: 18px;
    line-height: 1.6;
    margin: 25px 0 0 2px;
    padding-left: initial;
    letter-spacing: 0.5px;
  }
}

.leftVerticalLine,
.rightVerticalLine {
  display: none;
  position: absolute;
  z-index: 10;
  color: #ffffff9e;
  top: 0;
  height: 100%;
  padding: 70px 0 90px;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;

  @media screen and (min-width: 500px) {
    display: flex;
  }
}

.leftVerticalLine {
  left: 3px;
  writing-mode: tb-rl;
  transform: rotate(180deg);
}

.rightVerticalLine {
  right: 3px;
  writing-mode: tb-rl;
}

.verticalLine {
  height: 50px;
  width: 3px;
  background: #ffffff9e;
}
