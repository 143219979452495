$desktop-width: 1000px;

.sectionContainer {
  width: 100%;
  position: relative;
}

.sectionWrapper {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;

  @media screen and (min-width: $desktop-width) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px auto 0;
    gap: 20px;
  }
}

.textContainer {
  width: 100%;
  padding: 0 10px;

  @media screen and (min-width: $desktop-width) {
    width: 50%;
    justify-content: flex-start;
    padding: 60px 10px 0;
  }
}

.title {
  font-family: chaney, sans-serif;
  font-weight: 400;
  font-size: clamp(24px, 7vw, 28px);
  line-height: 1;
  transform: var(--matrix);
  width: 100%;
  max-width: 385px;
  margin: 0 auto;
  letter-spacing: 6px;
  text-align: center;

  @media screen and (min-width: $desktop-width) {
    max-width: 580px;
    font-size: clamp(45px, 4vw, 50px);
    text-align: left;
  }
}

.description {
  font-family: space-grotesk, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  transform: var(--matrix);
  width: 100%;
  max-width: 385px;
  margin: 40px auto 0;
  text-align: center;
  color: #abb0b2;

  @media screen and (min-width: $desktop-width) {
    font-size: clamp(18px, 1.5vw, 20px);
    max-width: 580px;
    line-height: 1.8;
    text-align: left;
  }
}
