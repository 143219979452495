/* stylelint-disable font-family-name-quotes */
@font-face {
  font-family: figtree;
  src: url("./Figtree-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: figtree;
  src: url("./Figtree-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: figtree;
  src: url("./Figtree-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: figtree;
  src: url("./Figtree-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: figtree;
  src: url("./Figtree-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: figtree;
  src: url("./Figtree-ExtraBold.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: space-grotesk;
  src: url("./SpaceGrotesk-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: space-grotesk;
  src: url("./SpaceGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: roboto-mono;
  src: url("./RobotoMono-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: roboto-mono;
  src: url("./RobotoMono-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: chaney;
  src: url("./Chaney.otf") format("opentype");
}
