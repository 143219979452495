$desktop-width: 1000px;

.sectionContainer {
  width: 100%;
  padding-top: 110px;
  transform: var(--matrix);

  @media screen and (min-width: $desktop-width) {
    padding-top: 220px;
  }
}

.title {
  font-family: chaney, sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 1;
  width: 100%;
  margin: 20px auto 0;
  letter-spacing: 6px;
  text-align: center;
  position: relative;
  z-index: 1;

  @media screen and (min-width: $desktop-width) {
    max-width: 580px;
    font-size: 50px;
  }
}

.phasesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  margin: 50px auto 0;
  gap: 20px;
  padding: 0 5px;

  @media screen and (min-width: $desktop-width) {
    margin: 100px auto 0;
    flex-direction: initial;
    justify-content: space-between;
    gap: 30px;
  }
}

.phase {
  width: 90%;
  max-width: 330px;
  height: 85px;
  background: #000234;
  border-radius: 30px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  position: relative;
  padding: 3px 10px 0;

  @media screen and (min-width: $desktop-width) {
    justify-content: center;
    gap: clamp(18px, 2vw, 20px);
    border-radius: 50px 0;
    max-width: 360px;
    height: 170px;
    padding: 0 5px;
  }

  &::before {
    content: "";
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    z-index: -1;
    border-radius: inherit;
    background: linear-gradient(0deg, #f9158c 27.51%, #f9158c 27.51%, #810da4 34.62%, #152bab 61.48%, #152bab00 75.61%);

    @media screen and (min-width: $desktop-width) {
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      background: linear-gradient(
        180deg,
        #f9158c 27.51%,
        #f9158c 27.51%,
        #810da4 34.62%,
        #152bab 61.48%,
        #152bab00 75.61%
      );
    }
  }
}

.phaseMiddle::before {
  background: linear-gradient(0deg, #f9158c 27.51%, #f9158c 27.51%, #810da4 34.62%, #152bab 61.48%, #152cac00 75.61%);
}

.textContainer {
  display: flex;
  align-items: center;

  @media screen and (min-width: $desktop-width) {
    flex-direction: column;
    align-items: initial;
    max-width: 215px;
  }
}

.phaseNumber {
  font-family: chaney, sans-serif;
  font-size: clamp(40px, 10vw, 45px);
  background-image: linear-gradient(to bottom, #70ee34 0%, #34eee3 100%);
  color: transparent;
  background-clip: text;
  min-width: 55px;
  text-align: center;

  @media screen and (min-width: $desktop-width) {
    min-width: initial;
    font-size: clamp(70px, 8vw, 85px);
  }
}

.phaseTitle {
  font-family: space-grotesk, sans-serif;
  font-size: clamp(12px, 4vw, 14px);
  font-weight: 700;
  color: #fbfdff;
  text-transform: uppercase;
  letter-spacing: 3px;
  width: 80px;
  min-width: 80px;

  @media screen and (min-width: $desktop-width) {
    font-size: clamp(20px, 2vw, 22px);
    margin-bottom: 10px;
    width: initial;
    min-width: initial;
  }
}

.phaseDesc {
  font-family: space-grotesk, sans-serif;
  font-size: clamp(10px, 3.2vw, 14px);
  font-weight: 400;
  color: #d4dcdf;
  letter-spacing: 1px;

  @media screen and (min-width: $desktop-width) {
    font-size: clamp(16px, 1.5vw, 18px);
  }
}

.lightMobile {
  position: relative;
  background-image: url("../../../../assets/images/home-roadmap-light-mobile.svg");
  height: 512px;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: -150px;
  z-index: -1;

  @media screen and (min-width: $desktop-width) {
    display: none;
  }
}

.lightDesktop1,
.lightDesktop2,
.lightDesktop3 {
  display: none;

  @media screen and (min-width: 1500px) {
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
  }
}

.lightDesktop1 {
  @media screen and (min-width: $desktop-width) {
    background-image: url("../../../../assets/images/home-roadmap-light-1-desktop.svg");
    top: 70%;
    left: 16px;
    width: clamp(400px, 30vw, 470px);
    height: 546px;
    transform: rotate(-3deg);
  }
}

.lightDesktop2 {
  @media screen and (min-width: $desktop-width) {
    background-image: url("../../../../assets/images/home-roadmap-light-2-desktop.svg");
    top: 37%;
    left: -25px;
    width: 404px;
    height: 540px;
  }
}

.lightDesktop3 {
  @media screen and (min-width: $desktop-width) {
    background-image: url("../../../../assets/images/home-roadmap-light-3-desktop.svg");
    top: 38%;
    left: -2px;
    width: 352px;
    height: 511px;
    transform: rotate(4deg);
  }
}
