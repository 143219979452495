$desktop-width: 1000px;

.sectionContainer {
  width: 100%;
}

.sectionWrapper {
  width: 100%;
  max-width: 1500px;
  margin: 40px auto 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: var(--matrix);

  @media screen and (min-width: $desktop-width) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 180px auto 0;
    gap: 20px;
  }
}

.textContainer {
  width: 100%;
}

.title {
  font-family: chaney, sans-serif;
  font-weight: 400;
  font-size: clamp(22px, 7vw, 28px);
  line-height: 1.4;
  width: 100%;
  margin: 0 auto;
  letter-spacing: 6px;
  text-align: center;

  @media screen and (min-width: $desktop-width) {
    max-width: 580px;
    font-size: 45px;
  }
}

.description {
  font-family: space-grotesk, sans-serif;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  max-width: 310px;
  margin: 15px auto 0;
  letter-spacing: 1px;
  text-align: center;
  color: #abb0b2;

  @media screen and (min-width: $desktop-width) {
    font-size: 20px;
    max-width: initial;
    text-align: center;
  }
}

.cardsContainer {
  margin-top: 40px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 0 10px;

  @media screen and (min-width: $desktop-width) {
    flex-wrap: nowrap;
    max-width: 1312px;
  }
}

.card {
  width: 45%;
  max-width: 155px;
  border-radius: 50px 0;
  position: relative;
  background: #000e2e;

  @media screen and (min-width: $desktop-width) {
    width: 210px;
    max-width: 210px;
    height: auto;
    aspect-ratio: 210 / 540;
  }

  &::before {
    content: "";
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    z-index: -1;
    border-radius: inherit;
    transition: background-image 200ms ease-out;
  }

  &:nth-child(odd)::before {
    background-image: linear-gradient(180deg, #f9158c 28%, #f9158c 28%, #810da4 35%, #152bab 60%, #152bab00 75%);
  }

  &:nth-child(even)::before {
    background-image: linear-gradient(0deg, #f9158c 28%, #f9158c 28%, #810da4 35%, #152bab 60%, #152cac00 75%);
  }

  &:hover::before {
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    background-image: linear-gradient(180deg, #69ee34 28%, #70ee34 28%, #34ee52 35%, #34ee7b 60%, #34eebf 75%);
  }

  .cardImageContainer {
    width: 100%;
    height: 138px;
    background-color: #003878;
    position: relative;
    border-top-left-radius: 50px;
    transition: background-color 200ms ease-out;
    overflow: hidden;

    @media screen and (min-width: $desktop-width) {
      height: auto;
      aspect-ratio: 210 / 420;
    }
  }

  .cardImage {
    width: 120%;
    height: 110%;
    object-fit: cover;
    object-position: -20px;
    filter: grayscale(1);
    transition: filter 150ms linear;

    @media screen and (min-width: $desktop-width) {
      width: 100%;
      height: 100%;
      object-position: initial;
    }
  }

  .cardNumber {
    position: absolute;
    left: 15px;
    bottom: 4px;
    font-size: 40px;
    color: #989898;
    transition: color 200ms ease-out;

    @media screen and (min-width: $desktop-width) {
      font-size: 65px;
    }
  }

  .cardTextContainer {
    width: 100%;
    padding: 0 8px 20px;

    @media screen and (min-width: $desktop-width) {
      padding: 0 5px 20px;
    }
  }

  .cardName {
    font-size: 16px;
    font-family: space-grotesk, sans-serif;
    font-weight: 700;
    color: #abb0b2;
    text-align: center;
    letter-spacing: 3px;
    text-transform: uppercase;
    margin-top: 5px;
    transition: color 200ms ease-out;

    @media screen and (min-width: $desktop-width) {
      margin-top: clamp(5px, 1vw, 18px);
      font-size: clamp(18px, 1.8vw, 22px);
    }
  }

  .cardDescription {
    font-size: 12px;
    font-family: space-grotesk, sans-serif;
    font-weight: 400;
    color: #abb0b2;
    text-align: center;
    margin-top: 2px;
    line-height: 1.1;
    transition: color 200ms ease-out;

    @media screen and (min-width: $desktop-width) {
      margin-top: 5px;
      font-size: clamp(12px, 1.2vw, 14px);
    }
  }

  &:hover {
    .cardImageContainer {
      background: #e25cc1;
    }

    .cardImage {
      filter: grayscale(0);
    }

    .cardNumber,
    .cardName,
    .cardDescription {
      color: #ffffff;
    }
  }
}
