.pageContainer {
  background-image: url("../../../assets/images/home-background-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.sectionsContainer {
  padding-top: 80px;
  margin-top: -45px;
  background-image: url("../../../assets/images/home-sections-background-mobile.jpg");
  background-repeat: no-repeat;
  background-position: 50% -780px;
  position: relative;
  clip-path: polygon(0% clamp(25px, 6vw, 45px), 100% 0%, 100% 100%, 0% 100%);

  @media screen and (min-width: 425px) {
    padding-top: 150px;
    background-image: url("../../../assets/images/home-sections-background-desktop.jpg");
    background-position: 54% 2%;
    margin-top: -145px;
    clip-path: polygon(0% clamp(90px, 7vw, 150px), 100% 0%, 100% 100%, 0% 100%);
  }

  @media screen and (min-width: 525px) {
    background-position: 54% -130%;
  }

  @media screen and (min-width: 1000px) {
    background-position: 50% 20%;
  }

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #070025 0%, transparent 2000px);
    pointer-events: none;

    @media screen and (min-width: 360px) {
      background-image: linear-gradient(180deg, #070025e4 0%, #070025e4 15%, transparent 1500px);
    }
  }
}

h2 {
  padding: 0;
  margin: 0;
  color: #ffffff;
}

.glitchSections {
  position: relative;
  width: 100%;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/images/background-glitch-3.png");
    background-size: 100% 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    pointer-events: none;
  }
}
