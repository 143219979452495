$desktop-width: 1000px;

.headerContainer {
  position: absolute;
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  background: #000234;
  backdrop-filter: blur(24px);
  z-index: 10;

  @media screen and (min-width: $desktop-width) {
    padding: 10px 40px;
    background: rgba(0, 2, 52, 0.5);
    height: 90px;
  }

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(180deg, #000000c9 0%, transparent 90%);

    @media screen and (min-width: $desktop-width) {
      height: 200px;
    }
  }
}

.headerWrapper {
  width: 100%;
  height: 100%;
  max-width: 1650px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.logoContainer {
  height: 100%;
  max-height: 67px;

  img {
    height: 100%;
  }
}

.logoMobile {
  width: 105px;
  height: auto;
  aspect-ratio: 53 / 11;

  @media screen and (min-width: $desktop-width) {
    display: none;
  }
}

.logoDesktop {
  display: none;

  @media screen and (min-width: $desktop-width) {
    display: block;
    width: 148px;
    height: auto;
    aspect-ratio: 366 / 119;
  }
}

.burguerButtonContainer {
  display: flex;
  align-items: center;

  img {
    width: 28px;
    height: auto;
    aspect-ratio: 5 / 4;
  }

  @media screen and (min-width: $desktop-width) {
    display: none;
  }
}

.navContainer {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0 20px;
  background: #000000d4;
  position: fixed;
  font-size: 16px;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  @media screen and (min-width: $desktop-width) {
    display: flex;
    flex-direction: initial;
    justify-content: flex-end;
    position: initial;
    background: initial;
    width: initial;
    height: initial;
    padding-left: 20px;
    flex: 1;
  }
}

.nav {
  width: 100%;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0 clamp(10px, 1.5vw, 20px);

  @media screen and (min-width: $desktop-width) {
    justify-content: flex-end;
    flex-direction: initial;
    margin-top: initial;
    padding-left: 20px;
  }
}

.link {
  margin: 30px 0;
  list-style-type: none;
  width: 100%;
  max-width: 140px;

  @media screen and (min-width: $desktop-width) {
    margin: 0 10px;
  }

  a {
    text-decoration: none;
    color: #ffffffb0;
    display: flex;
    justify-content: center;
    font-family: space-grotesk, sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    position: relative;
    text-align: center;
    padding-bottom: 14px;
    transition: color 200ms ease-out;

    &:hover {
      font-weight: 700;
      color: #ffffff;
    }

    &::before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #ffffffd5;
      pointer-events: none;
    }

    &:hover::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      pointer-events: none;
      height: 3px;
      background-color: #ffffff;
      animation: link-line 350ms ease-out forwards;
    }
  }
}

@keyframes link-line {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.linkActive a {
  font-weight: 700;
  color: #ffffff;

  &::before {
    bottom: 0;
    height: 3px;
    background-color: #ffffff;
  }
}

.ctaButton {
  min-width: fit-content;
  font-family: roboto-mono, sans-serif;
  font-size: inherit;
  font-weight: 400;
  color: #ffffff;
  margin: 40px 0 0;
  padding: 14px 40px;
  position: relative;
  transition: color 200ms ease-out, background-color 250ms ease-out;
  clip-path: polygon(12% 0%, 100% 0%, 100% 50%, 88% 100%, 0% 100%, 0% 50%, 12% 0%);

  &:hover {
    background-color: #ffffff;
    color: #000000;
    font-weight: 500;
  }

  &::before {
    position: absolute;
    content: "";
    background-image: url("../../../assets/images/button-border-white.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: $desktop-width) {
    margin: 0 0 0 20px;
  }
}

// El ctaButton hace un salto al cargar la fuente roboto-mono a 500 por primera vez
// este punto es usado para cargar la fuente directamente al cargar la web, porque
// no se está usando en ningún otro sitio.
.point {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  font-family: roboto-mono, sans-serif;
  font-weight: 500;
}
