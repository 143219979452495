* {
  font-family: figtree, sans-serif;
  font-display: swap;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: 100%;

  --desktopWidth: 500px;
  --matrix: matrix(1, -0.08, 0, 1, 0, 0);
  --matrixSoft: matrix(1, -0.07, 0, 1, 0, 0);
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: figtree, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

main {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
  transition: box-shadow 100ms linear;
}

input {
  outline: 0;
}
