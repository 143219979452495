$desktop-width: 1000px;

.sectionContainer {
  width: 100%;
  padding-top: 415px;
  transform: var(--matrixSoft);

  @media screen and (min-width: $desktop-width) {
    padding-top: 380px;
  }
}

.sectionWrapper {
  width: 90%;
  max-width: 1350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: $desktop-width) {
    align-items: flex-start;
  }
}

.titleContainer {
  max-width: 240px;

  @media screen and (min-width: $desktop-width) {
    max-width: 440px;
  }
}

.surTitle {
  font-family: space-grotesk, sans-serif;
  font-weight: 700;
  font-size: 14px;
  background-image: linear-gradient(120deg, #152bab 5%, #810da4 20%, #f9158c 40%, #f9158c 80%);
  color: transparent;
  background-clip: text;
  text-transform: uppercase;
  width: 100%;
  padding: 0 5px;
  line-height: 1.3;
  letter-spacing: 4.5px;
  text-align: center;
  text-shadow: 22px 4px 32px #001a5e;

  @media screen and (min-width: $desktop-width) {
    background-image: linear-gradient(120deg, #c3ee34 5%, #70ee34 30%, #34ee52 50%, #34eee3 80%);
    font-size: clamp(18px, 2.2vw, 22px);
    padding: initial;
    max-width: 260px;
    line-height: 1.4;
    text-align: left;
  }
}

.title {
  font-family: chaney, sans-serif;
  font-weight: 400;
  font-size: 25px;
  color: #0d1baf;
  line-height: 1.2;
  width: 100%;
  margin: 20px auto 0;
  letter-spacing: 4px;
  text-align: center;
  position: relative;
  z-index: 1;

  @media screen and (min-width: $desktop-width) {
    color: #fbfdff;
    max-width: 580px;
    font-size: 45px;
    text-align: left;
  }
}

.description {
  font-family: space-grotesk, sans-serif;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  max-width: 390px;
  margin-top: 30px;
  line-height: 1.6;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 3px 6px #121212;

  @media screen and (min-width: $desktop-width) {
    font-size: 18px;
    text-align: left;
  }
}

.benefitsContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}

.benefit {
  display: flex;
  gap: 10px;
}

.benefitIcon {
  width: 56px;
  height: 42px;
  background-image: url("../../../../assets/icons/benefit-background.svg");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: $desktop-width) {
    width: 66px;
    height: 50px;
  }

  img {
    transform: scale(0.7);

    @media screen and (min-width: $desktop-width) {
      transform: scale(0.9);
    }
  }
}

.benefitText {
  background: rgba(0, 2, 52, 0.75);
  color: #ffffff;
  display: flex;
  align-items: center;
  width: 270px;
  height: 38px;
  font-family: space-grotesk, sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding-left: 8px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 55%, 93% 100%, 0% 100%);

  @media screen and (min-width: $desktop-width) {
    font-size: 18px;
    width: 335px;
    height: 45px;
    padding-left: 20px;
  }
}
