.sliderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  gap: clamp(10px, 6vw, 40px);

  @media screen and (min-width: 1000px) {
    margin-top: initial;
  }
}

.cardsContainer {
  position: relative;
  width: 220px;
  height: 250px;
  aspect-ratio: 1 / 1;
  transform: translateX(3px) var(--matrixSoft);

  @media screen and (min-width: 1000px) {
    transform: translateX(-10px) var(--matrixSoft);
    width: 360px;
    height: 360px;
  }
}

.card {
  --time: 500ms;

  position: absolute;
  width: 95%;
  max-width: 160px;
  height: auto;
  aspect-ratio: 220 / 240;
  border-radius: 35px 0;
  transition: top var(--time) linear, left var(--time) linear, opacity 400ms linear, transform var(--time) linear;
  z-index: 1;

  @media screen and (min-width: 1000px) {
    aspect-ratio: 350 / 360;
    max-width: 350px;
    border-radius: 80px 0;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: radial-gradient(170% 420% at 100% 0%, #b4dfff 0%, #0f23ea 100%);
    transition: filter var(--time) ease-in-out;
    filter: grayscale(1);
  }

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: -1;
    border-radius: inherit;
    background-image: linear-gradient(
      180deg,
      #f9158c 27.51%,
      #f9158c 27.51%,
      #810da4 34.62%,
      #152bab 61.48%,
      #152bab00 75.61%
    );
  }

  &:nth-child(1) {
    top: 20px;
    left: -20px;
    z-index: 5;
    opacity: 0;
    transform: scale(1);
  }

  &:nth-child(2) {
    top: 0;
    left: 0;
    z-index: 4;
    transform: scale(1);
    opacity: 1;

    img {
      filter: grayscale(0);
    }
  }

  &:nth-child(3) {
    top: -40px;
    left: 40px;
    z-index: 3;
    transform: scale(0.9);
    opacity: 1;
  }

  &:nth-child(4) {
    top: -60px;
    left: 60px;
    z-index: 2;
    transform: scale(0.9);
    opacity: 1;
  }

  &:nth-child(5) {
    top: -80px;
    left: 80px;
    z-index: 1;
    transform: scale(0.9);
    opacity: 0;
  }

  /*   &:nth-child(3) img,
  &:nth-child(4) img,
  &:nth-child(5) img {
    filter: grayscale(1);
  } */
}

.activeCard {
  &::before {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    z-index: -1;
    border-radius: inherit;
    background-image: linear-gradient(180deg, #69ee34 28%, #70ee34 28%, #34ee52 35%, #34ee7b 60%, #34eebf 75%);
  }
}

.arrowLeft {
  transform: translateY(-10px);

  @media screen and (min-width: 1000px) {
    transform: translateY(40px);
  }
}

.arrowRight {
  transform: translateY(-80px);
}

.arrowRight,
.arrowLeft {
  width: clamp(30px, 10vw, 45px);
  height: auto;
  aspect-ratio: 29/66;
  transition: filter 200ms linear;
  position: relative;

  @media screen and (min-width: 1000px) {
    width: 56px;
  }

  &:hover {
    filter: hue-rotate(-50deg) brightness(2);
  }
}
