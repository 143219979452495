$desktop-width: 1000px;

.sectionContainer {
  position: relative;
  width: 100%;
  margin-top: 40px;
  padding-top: 80px;
  z-index: 1;

  @media screen and (min-width: $desktop-width) {
    margin-top: 150px;
    padding-top: 175px;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: chaney, sans-serif;
  font-weight: 400;
  font-size: 25px;
  color: #ffffff;
  line-height: 1;
  width: 100%;
  margin: 25px auto 0;
  letter-spacing: 10px;
  text-align: center;
  position: relative;
  z-index: 1;

  @media screen and (min-width: $desktop-width) {
    font-size: 50px;
  }
}

.faqsContainer {
  width: 95%;
  max-width: 600px;
  margin: 50px auto 0;
  position: relative;

  @media screen and (min-width: $desktop-width) {
    max-width: 920px;
  }

  a {
    color: #f41589;
  }
}

:global {
  section {
    border-bottom: 0 !important;
  }

  .faq-body {
    width: 100%;
    margin: 0 auto;
  }

  .row-title {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    font-size: clamp(17px, 3vw, 20px) !important;
    font-weight: 700;
    padding: 15px 25px 15px 30px !important;
    position: relative;
    background-color: #020230;

    &::before {
      content: "";
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      z-index: -1;
      border-radius: inherit;
      background: linear-gradient(
        180deg,
        #f9158c 27.51%,
        #f9158c 27.51%,
        #810da4 34.62%,
        #152bab 61.48%,
        #152bab00 75.61%
      );
    }
  }

  .row-title-text {
    font-family: space-grotesk, sans-serif;
    font-weight: 700;
    font-size: clamp(16px, 3vw, 18px) !important;
    color: #ffffff;
  }

  .faq-row-wrapper {
    background-color: transparent !important;
    border-radius: 20px;
    padding: 0 15px;
  }

  .faq-row {
    flex-direction: column;
    position: relative;
    margin-bottom: 15px;
  }

  .row-content-text {
    font-family: space-grotesk, sans-serif;
    font-weight: 400;
    line-height: 1.6;
    font-size: clamp(16px, 3vw, 18px) !important;
    color: #d4dcdf !important;
    padding: 35px 25px 30px !important;
  }

  .icon-wrapper {
    right: 20px !important;
  }
}
