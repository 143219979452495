$desktop-width: 1000px;

.menuContainer {
  margin: 0 auto;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: #241e3c;
}

.menuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 10px 20px;
}

.logoContainer {
  height: 100%;
  max-height: 67px;

  img {
    height: 100%;
  }
}

.logoMobile {
  width: 105px;
  height: auto;
  aspect-ratio: 53 / 11;

  @media screen and (min-width: $desktop-width) {
    display: none;
  }
}

.logoDesktop {
  display: none;

  @media screen and (min-width: $desktop-width) {
    display: block;
    width: 148px;
    height: auto;
    aspect-ratio: 366 / 119;
  }
}

.closeButton {
  display: flex;
  align-items: center;

  img {
    width: 26px;
    height: auto;
    aspect-ratio: 27 / 26;
  }
}

.navContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0 20px;
  font-size: 16px;
  padding: 50px 0;
}

.nav {
  width: 100%;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 0 clamp(10px, 1.5vw, 20px);

  @media screen and (min-width: $desktop-width) {
    justify-content: flex-end;
    flex-direction: initial;
    margin-top: initial;
    padding-left: 20px;
  }
}

.link {
  margin: 30px 0;
  list-style-type: none;
  width: 100%;
  max-width: 140px;

  @media screen and (min-width: $desktop-width) {
    margin: 0 10px;
  }

  a {
    text-decoration: none;
    color: #ffffffb0;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-family: space-grotesk, sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    position: relative;
    text-align: center;

    &::after {
      position: absolute;
      content: "";
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ffffffd5;
      pointer-events: none;
    }
  }
}

.linkActive a {
  font-weight: 700;
  background: radial-gradient(406.27% 594.05% at 0% 8.75%, #c3ee34 0%, #70ee34 31.77%, #34ee52 57.54%, #34eee3 100%);
  color: transparent;
  background-clip: text;

  &::after {
    bottom: -10px;
    height: 3px;
    background: #22e4ff;
  }
}

.ctaButton {
  min-width: fit-content;
  font-family: roboto-mono, sans-serif;
  font-size: inherit;
  font-weight: 400;
  background: #171130;
  color: #ffffff;
  margin: 40px 0 0;
  padding: 14px 40px;
  position: relative;
  clip-path: polygon(12% 0%, 100% 0%, 100% 50%, 88% 100%, 0% 100%, 0% 50%, 12% 0%);

  &:hover {
    background: #ffffff;
    color: #000000;
    font-weight: 500;
  }

  &::before {
    position: absolute;
    content: "";
    background-image: url("../../../../assets/images/button-border-white.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: $desktop-width) {
    margin: 0 0 0 20px;
  }
}

.socialContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  ul {
    width: 100%;
    max-width: 250px;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
  }

  li {
    position: relative;
  }
}

.socialIcon {
  width: 30px;
  transition: transform 50ms;

  &:hover {
    transform: scale(1.15);
  }
}
