.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999999;

  @media screen and (min-width: 600px) {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.boxWrapper {
  margin: 0 auto;
  width: 95%;
  max-width: 360px;
  height: min-content;
  filter: drop-shadow(0 1px #9b005d) drop-shadow(1px 1px #9b005d) drop-shadow(0 -1px #9b005d)
    drop-shadow(-1px 0 #9b005d);

  @media screen and (min-width: 600px) {
    max-width: 830px;
  }
}

.box {
  position: relative;
  padding: 100px 50px 60px;
  color: #abb0b2;
  background-color: #080125;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
  font-family: space-grotesk, sans-serif;
  text-align: center;
  clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 0% 7%);

  @media screen and (min-width: 600px) {
    padding: 60px 50px;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 35%);
  }
}

.whiteLine {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: 70px;
  width: 200px;
  height: 1px;
  background-color: #ffffff;

  @media screen and (min-width: 600px) {
    top: 30px;
    right: 50px;
    transform: none;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background-color: #ffffff;
  }
}

.title {
  font-family: inherit;
  font-weight: 700;
  font-size: 23px;
  max-width: 250px;
  background: linear-gradient(to right, #c3ee34 0%, #70ee34 21.6%, #34ee52 52.59%, #34eee3 100%);
  background-clip: text;
  color: transparent;
  text-shadow: 22px 4px 32px #001a5e;
  display: inline-block;
  transform: matrix(1, 0, -0.05, 1, 0, 0);
  letter-spacing: 2px;

  @media screen and (min-width: 600px) {
    max-width: initial;
  }
}

.description {
  margin-top: 40px;
  font-family: inherit;
  font-size: clamp(16px, 3vw, 19px);
  font-weight: 400;
  max-width: 255px;
  display: inline-block;
  transform: matrix(1, 0, -0.07, 1, 0, 0);
  letter-spacing: 0.8px;
  line-height: 1.7;

  @media screen and (min-width: 600px) {
    max-width: 660px;
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  margin: 40px 0 0;
  gap: 30px 50px;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    margin: 60px 0 0;
  }
}

.declineButton {
  width: fit-content;
  min-width: 215px;
  font-family: roboto-mono, sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  padding: 18px 65px;
  position: relative;
  transition: color 200ms ease-out, background-color 250ms ease-out;
  clip-path: polygon(12% 0%, 100% 0%, 100% 50%, 88% 100%, 0% 100%, 0% 50%, 12% 0%);

  &:hover {
    background-color: #ffffff;
    color: #000000;
    font-weight: 500;
  }

  &::before {
    position: absolute;
    content: "";
    background-image: url("../../../assets/images/button-border-white.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.acceptButton {
  width: fit-content;
  min-width: 215px;
  padding: 18px 70px;
  position: relative;
  clip-path: polygon(12% 0%, 100% 0%, 100% 50%, 88% 100%, 0% 100%, 0% 50%, 12% 0%);

  .acceptButtonBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 200ms ease-out;
    background-image: radial-gradient(170% 140% at 0 0%, #c3ee34 0%, #70ee34 30%, #34ee52 70%, #34eee3 100%);
  }

  .acceptButtonText {
    display: inline-block;
    font-family: roboto-mono, sans-serif;
    font-size: 20px;
    font-weight: 500;
    background-image: radial-gradient(
      186.74% 93.31% at 60.63% 0%,
      #c3ee34 0%,
      #70ee34 31.77%,
      #34ee52 69.79%,
      #34eee3 100%
    );
    background-clip: text;
    color: transparent;
    transition: color 200ms ease-out;
    position: relative;
  }

  &:hover .acceptButtonText {
    color: #ffffff;
    font-weight: 500;
  }

  &:hover .acceptButtonBackground {
    opacity: 0.9;
  }

  &::before {
    position: absolute;
    content: "";
    background-image: url("../../../assets/images/button-border-gradient.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
